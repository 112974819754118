import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

const GclidCookie = () => {

    const [cookies, setCookie] = useCookies(["gclid"])

    useEffect(() => {

        const queryString = typeof window !== "undefined" ? window.location.search : ""
        const urlParams = new URLSearchParams(queryString)
        const gclid = urlParams ? urlParams.get("gclid") : ""
        const currentDate = new Date()
        const nextMonth = new Date(currentDate)

        nextMonth.setMonth(currentDate.getMonth() + 1)

        if( gclid ) {
            setCookie("gclid", gclid, { path: "/", expires: nextMonth })
        }

    }, [cookies, setCookie])
    
    return false

}

export default GclidCookie