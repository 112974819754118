import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Logo from "../../images/logo.svg"

const NavItem = props => {
  return (
    <div
      className={`px-6 py-4 border-b border-grey-300 uppercase font-bold font-body tracking-wider bg-white static leading-5 focus:outline-none`}
      role="button"
      tabIndex={0}
      onClick={props.onClick}
      onKeyDown={props.onClick}
    >
      <span className="text-xsmenu">{props.label}</span>
      <i
        className="fal fa-angle-right text-base text-gold align-middle float-right mt-1"
        aria-hidden
      ></i>
    </div>
  )
}

const NavSlide = props => {
  return (
    <div
      className={`absolute flex flex-grow float-left flex-col bg-offwhite top-0 w-screen transition-all duration-500 overflow-y-scroll ${
        props.show ? "right-0" : "right-[-100%]"
      }`}
    >
      <span
        className="block px-6 py-4 border-b border-grey-300 text-xsmenu uppercase font-bold tracking-wider font-body text-gold bg-white leading-5 focus:outline-none"
        role="button"
        tabIndex={0}
        onClick={props.onClick}
        onKeyDown={props.onClick}
      >
        <i
          className="fal fa-angle-left text-base text-gold align-middle mr-1"
          aria-hidden
        ></i>{" "}
        {props.label}
      </span>
      {props.items.map((navItem, index) => {
        return (
          <div
            key={index}
            className={`transform transition-all duration-500 ${
              props.show
                ? "opacity-1 translate-x-0"
                : "opacity-0 -translate-x-3"
            }`}
            style={{
              transitionDelay: `${props.show ? `${index + 4}00ms` : "0ms"}`,
            }}
          >
            <Link
              className="block px-6 py-4 border-b border-grey-300 text-xs font-body normal-case font-normal tracking-normal leading-5 focus:outline-none"
              to={`${navItem.target}`}
              onClick={props.closeParent}
              onKeyDown={props.closeParent}
              key={index}
            >
              {navItem.label}
            </Link>
          </div>
        )
      })}
    </div>
  )
}

const NavigationMobile = props => {
  const [state, setState] = useState(
    props.items.map(item => {
      return {
        ...item,
        show: false,
      }
    })
  )

  const [show, setShow] = useState(false)
  const [childOpen, setChildOpen] = useState(false)

  const handleClick = () => {
    setShow(!show)
    setChildOpen(false)
    setState(prevState =>
      prevState.map(item => {
        return {
          ...item,
          show: false,
        }
      })
    )
  }

  const updateState = value => {
    setChildOpen(!childOpen)
    setState(prevState =>
      prevState.map(item => {
        return {
          ...item,
          show: item.label === value ? !item.show : false,
        }
      })
    )
  }

  const [animate, setAnimate] = useState(true)
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    setTimeout(() => setAnimate(false), 2000)
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [scrolled])

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true)
      setAnimate(true)
    } else {
      setScrolled(false)
      setAnimate(false)
    }
  }

  return (
    <nav
      className={`z-20 flex px-6 relative bg-white border-b border-gray-200 items-center xl:hidden py-2`}
    >
      <Link
        className="md:block hidden mr-auto text-darkgrey"
        to="/contact/"
        aria-label="Contact Us"
      >
        <img src="/message.svg" alt="Contact" width="18" height="13"/>
      </Link>
      <a href="tel:+44 800 138 6688" className="text-darkgrey duration-500 font-body font-bold text-lg md:hidden block mr-auto">
        <i
          className="fal fa-phone-alt mr-1 align-middle"
          aria-hidden
        ></i>
      </a>
      <Link className="block w-28 mx-auto" to="/" aria-label="Deuren Homepage">
        <Logo
          className={`w-full overflow-visible ${animate ? "animate" : ""}`}
        />
      </Link>
      <span
        className="ml-auto w-4 h-4 relative focus:outline-none"
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        <span className="hidden">Menu</span>
        <div
          className={`bg-darkgrey h-0.1 w-full transition-all duration-500 absolute left-0 transform origin-center ${
            show ? "top-1/2 rotate-135" : "top-1/3 rotate-0"
          }`}
        ></div>
        <div
          className={`bg-darkgrey h-0.1 w-full transition-all duration-500 absolute left-0 transform origin-center ${
            show ? "top-1/2 -rotate-135" : "top-2/3 rotate-0"
          }`}
        ></div>
      </span>
      <div
        className={`fixed flex flex-col w-screen bg-white overflow-y-scroll transition-all duration-500 z-50 ${
          show ? "left-0" : "left-[-100%]"
        }`}
        style={{
          top: "86px",
          minHeight: "calc(100% - 86px)",
          maxHeight: "calc(100% - 86px)",
        }}
      >
        <div
          className={`bg-offwhite overflow-y-scroll overflow-x-hidden flex flex-col flex-1 relative`}
        >
          <div
            className={`absolute flex flex-grow flex-col w-full transition-all duration-500 ${
              childOpen ? "-left-full" : "left-0"
            }`}
          >
            {state.map((navItem, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className={`transform transition-all duration-500 ${
                      show && !childOpen
                        ? "opacity-1 translate-x-0"
                        : "opacity-0 -translate-x-3"
                    }`}
                    style={{
                      transitionDelay: `${
                        show && !childOpen ? `${index + 4}00ms` : "0ms"
                      }`,
                    }}
                  >
                    {navItem.items.length ? (
                      <NavItem
                        label={navItem.label}
                        items={navItem.items}
                        scrolled={scrolled}
                        closeParent={handleClick}
                        onClick={() => updateState(navItem.label)}
                        show={navItem.show}
                      />
                    ) : (
                      <Link
                        className="block px-6 py-4 border-b border-grey-300 text-xsmenu uppercase font-bold font-body tracking-widest bg-white leading-5 focus:outline-none"
                        to={`${navItem.target}`}
                        onClick={handleClick}
                        onKeyDown={handleClick}
                      >
                        {navItem.label}
                      </Link>
                    )}
                  </div>
                </React.Fragment>
              )
            })}
          </div>
          {state.map((navItem, index) => {
            return (
              <React.Fragment key={index}>
                {navItem.items.length > 0 && (
                  <NavSlide
                    label={navItem.label}
                    items={navItem.items}
                    scrolled={scrolled}
                    closeParent={handleClick}
                    onClick={() => updateState(navItem.label)}
                    show={navItem.show}
                  />
                )}
              </React.Fragment>
            )
          })}
        </div>

        <div className="bg-darkgrey p-6">
          <ul className="list-none text-xs text-white mb-4">
            <li className="mb-3">
              <a href="/request-a-quote/" className="text-white">
                Request a Quote
              </a>
            </li>
            <li className="mb-3">
              <a href="/brochure-downloads/" className="text-white">
                Brochure Downloads
              </a>
            </li>
            <li className="mb-3">
              <a href="/architects-downloads/" className="text-white">
                Architects Downloads
              </a>
            </li>
          </ul>
          <ul className="list-none text-white flex flex-row">
            <li className="mr-3">
              <a href="https://www.facebook.com/deurenuk/" aria-label="Facebook" className="text-white" target="_blank" rel="noreferrer">
                <i className="fab fa-facebook" aria-hidden></i>{" "}
              </a>
            </li>
            <li className="mr-3">
              <a href="https://www.instagram.com/deurendoors/" aria-label="Instagram" className="text-white" target="_blank" rel="noreferrer">
                <i className="fab fa-instagram" aria-hidden></i>{" "}
              </a>
            </li>
            <li className="mr-3">
              <a href="https://twitter.com/DeurenDoors?lang=en-gb" aria-label="Twitter" className="text-white" target="_blank" rel="noreferrer">
                <i className="fab fa-twitter" aria-hidden></i>{" "}
              </a>
            </li>
            <li className="mr-3">
              <a href="https://www.pinterest.co.uk/deurendoors/" aria-label="Pinterest" className="text-white" target="_blank" rel="noreferrer">
                <i className="fab fa-pinterest" aria-hidden></i>{" "}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavigationMobile
