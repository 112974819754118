import React, { useState, useEffect } from "react"
import { useCookies } from "react-cookie"
import { Link } from "gatsby"
import Fade from "react-reveal/Fade"

const current = new Date()
const nextYear = new Date()
nextYear.setFullYear(current.getFullYear() + 1)

const CookieBar = props => {
  const [cookies, setCookie] = useCookies(["cookie_policy"])
  const [state, setState] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    setState(cookies.cookie_policy)
  }, [cookies.cookie_policy])

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if( window.scrollY > 0 ) {
        setScrolled(true)
      }
    })
  })

  function handleClick() {
    setCookie("cookie_policy", true, { path: "/", expires: nextYear })
    setState(true)
  }

  return (
    <Fade>
      <div
        className={`bg-black text-white px-6 py-3 opacity-0 ${state || !scrolled ? "hidden" : "block"}`}
      >
        <div
          className={`container xl:mx-auto mx-0 p-0 flex flex-row justify-center items-center`}
        >
          <p className="mb-0 xl:text-xs text-xxs mr-4">
            We use cookies to give you the best experience possible. Click
            accept to continue browsing or find out more in our cookie policy{" "}
            <Link to="/cookie-policy/" className="text-gold underline">
              here
            </Link>
            .
          </p>
          <button
            className="btn small text-xxs gold"
            type="button"
            tabIndex="0"
            onClick={handleClick}
          >
            Accept
          </button>
        </div>
      </div>
    </Fade>
  )
}

export default CookieBar
