import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Logo from "../../images/logo.svg"
import Img from "gatsby-image"

const Dropdown = props => {
  const [show, setShow] = useState(false)

  const handleClick = () => {
    setShow(false)
  }

  return (
    <div
      className={`group cursor-default flex-initial relative hover:text-gold focus:outline-none ${
        props.push_right ? "ml-auto" : ""
      }`}
      role="button"
      tabIndex={0}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <div
        className={`border-transparent border-b transition-all duration-300 ease-in-out group-hover:border-gold cursor-default mx-3 -mb-px ${
          props.scrolled ? "py-4" : "py-6"
        }`}
      >
        <span className="font-body uppercase text-xsmenu tracking-widest font-bold">
          {props.label}
        </span>
        <i
          className="fal fa-angle-down ml-1 align-middle text-gold"
          aria-hidden
        ></i>
      </div>
      <div
        className={`fixed cursor-default left-0 w-screen bg-offwhite transition-all duration-500 ease-in-out transform origin-top ${
          show ? "scale-y-100 z-40 shadow" : "scale-y-0"
        } ${props.scrolled ? "top-[57px]" : "top-[101px]"}`}
      >
        <div
          className={`flex flex-row p-14 transition-opacity duration-500 ${
            show ? "opacity-100 delay-300" : "opacity-0 delay-0"
          } ease-in-out`}
        >
          <div className="flex-1 px-3">
            <ul className="list-none">
              <li className="uppercase text-xsmenu text-darkgrey cursor-default tracking-widest mb-4 font-body font-bold">
                {props.label}
              </li>
              {props.items.map((item, index) => {
                return (
                  <li className="mb-1" key={index}>
                    <Link
                      className="text-xs text-darkgrey font-body hover:text-gold transform transition-all duration-300 ease-in-out focus:outline-none"
                      to={`${item.target}`}
                      onClick={handleClick}
                      onTouchStart={handleClick}
                    >
                      {item.label}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="flex-grow w-8/12">
            <h3 className="uppercase text-xsmenu text-darkgrey cursor-default tracking-widest mb-5 font-body font-bold px-3">
              {props.featured_title}
            </h3>
            <div className="flex">
              {props.featured_items.map((item, index) => {
                return (
                  <div className="flex-1 px-3 featured-item" key={index}>
                    <Link
                      className="text-darkgrey hover:text-gold transition duration-300 ease-in-out block relative"
                      to={`${item.target}`}
                      onClick={handleClick}
                      onTouchStart={handleClick}
                    >
                      <Img
                        className="mb-3"
                        fluid={item.featured_image}
                        alt={item.label}
                      />
                      {item.play_button && (
                        <img
                          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mb-3"
                          src={"/video.svg"}
                          alt="Video play button"
                        />
                      )}
                      <p className="text-xs font-body">{item.label}</p>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const NavigationDesktop = props => {
  const [animate, setAnimate] = useState(true)
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (!scrolled) {
        setAnimate(false)
      }
    }, 2000)
    window.addEventListener("scroll", handleScroll)
  }, [scrolled])

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true)
      setAnimate(true)
    } else {
      setScrolled(false)
      setAnimate(false)
    }
  }

  return (
    <nav className="px-14 z-40 relative bg-white border-b border-grey-100 items-center hidden xl:flex">
      <Link
        className="absolute left-1/2 w-28 transform -translate-x-1/2"
        to="/"
        aria-label="Deuren Homepage"
      >
        <Logo
          className={`w-full overflow-visible ${animate ? "animate" : ""}`}
        />
      </Link>
      {props.items.map((navItem, index) => {
        return (
          <React.Fragment key={index}>
            {navItem.items.length ? (
              <Dropdown
                label={navItem.label}
                items={navItem.items}
                featured_items={navItem.featured_items}
                featured_title={navItem.featured_title}
                push_right={navItem.push_right}
                scrolled={scrolled}
              ></Dropdown>
            ) : (
              <Link
                className={`flex-initial font-body uppercase text-xsmenu tracking-widest font-bold mx-3 -mb-px hover:text-gold transition duration-300 ease-in-out focus:outline-none ${
                  scrolled ? "py-4" : "py-6"
                } ${navItem.push_right ? "ml-auto" : ""}`}
                to={`${navItem.target}`}
              >
                {navItem.label}
              </Link>
            )}
          </React.Fragment>
        )
      })}
    </nav>
  )
}

export default NavigationDesktop
