require("/src/styles/global.css")
require("slick-carousel/slick/slick.css")
require("slick-carousel/slick/slick-theme.css")
require('@fontsource/montserrat/400.css')
require('@fontsource/montserrat/500.css')
require('@fontsource/montserrat/600.css')
require('@fontsource/montserrat/700.css')

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    window.scrollTo(0, 0, "auto")
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.scrollTo(...(savedPosition || [0, 0]), "auto")
  }
  return false
}

exports.onInitialClientRender = () => {
  
  // eslint-disable-next-line no-undef
  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  (function(){
  var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  s1.async=true;
  s1.src='https://embed.tawk.to/5a9e9d2cd7591465c7084e33/default';
  s1.charset='UTF-8';
  s1.setAttribute('crossorigin','*');
  s0.parentNode.insertBefore(s1,s0);
  })();
    
}